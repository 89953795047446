<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col xs="12" lg="12">
        <b-card>
          <div>
            <h4 class="d-inline"><i class="fa fa-users"></i> All Users List</h4>
          </div>
          <hr class="clearfix my-4" />
          <vue-json-to-csv :labels="labels" :csv-title="'Users List'" :json-data="wtsData">
            <button class="btn btn-blue float-right">
              Download CSV
              <i class="fa fa-download"></i>
            </button>
          </vue-json-to-csv>
          <v-client-table :columns="columns" :data="wtsData" :options="options" :theme="theme" class="no-overflow"
            ref="table" id="dataTable">
            <div v-if="isProject(props.row)" slot="toggler" slot-scope="props">
              <i class="fa fa-chevron-circle-down" aria-hidden="true" @click="toggle(props.row.id, $event)"></i>
            </div>
            <div slot="status" slot-scope="props">
              <span v-if="props.row.status == 'Active'" class="text-success">
                <button class="btn btn-sm btn-success w-100"
                  @click="deactivateVendor(props.row.user_id, props.row.role)">Active</button>
              </span>
              <span v-if="props.row.status == 'Inactive'" class="text-danger">
                <button class="btn btn-sm btn-danger w-100"
                  @click="activateVendor(props.row.user_id, props.row.role)">Inactive</button>
              </span>
            </div>
            <div slot="actions" slot-scope="props">
              <div v-if="props.row.role == 'Manager'">
                <b-button class="mr-2 btn-sm" @click="sendInfoManger(props.row)"><i class="fa fa-edit"></i> Edit
                  Authority</b-button>
              </div>
            </div>

            <span slot="creation_date" slot-scope="props">{{
                props.row.creation_date | moment("M/D/YYYY")
            }}</span>

            <div v-if="isProject(props.row)" slot="child_row" slot-scope="props">
              <div v-if="props.row.role.toLowerCase() == 'wss'">
                <v-client-table :data="props.row.projects" :columns="columnsWSSProject" :options="optionWSSProject"
                  :ref="wssChildRow" :theme="theme" class="no-overflow">
                  <div slot="status" slot-scope="props">
                    <span v-if="props.row.status == 0" class="text-success">
                      <button type="button" class="btn btn-success" @click="deactivateProject(props.row.wpid, 'wss')">
                        Active
                      </button>
                    </span>
                    <span v-if="props.row.status == 1" class="text-danger">
                      <button type="button" class="btn btn-danger" @click="activateProject(props.row.wpid, 'wss')">
                        Inactive
                      </button>
                    </span>
                    <span v-if="props.row.status == 2" class="text-danger">
                      <span type="text" class="text-danger">
                        Inactive
                      </span>
                    </span>
                  </div>
                </v-client-table>
              </div>
              <div v-else>
                <v-client-table :data="props.row.projects" :columns="columnsProject" :options="optionProject"
                  :theme="theme" class="no-overflow">
                  <div slot="status" slot-scope="props">
                    <span v-if="props.row.status == 0" class="text-success">
                      <button type="button" class="btn btn-success" @click="deactivateProject(props.row.wpid, 'wts')">
                        Active
                      </button>
                    </span>
                    <span v-if="props.row.status == 1" class="text-danger">
                      <button type="button" class="btn btn-danger" @click="activateProject(props.row.wpid, 'wts')">
                        Inactive
                      </button>
                    </span>
                    <span v-if="props.row.status == 2" class="text-danger">
                      <span type="text" class="text-danger">
                        Inactive
                      </span>
                    </span>
                  </div>
                  <div slot="actions" slot-scope="props">
                    <b-button size="sm" v-b-modal.editModal2 variant="primary" @click="
                      sendId(
                        props.row.authority,
                        props.row.wtsid,
                        props.row.managerid,
                        props.row.passlimit,
                        props.row.wpid
                      )
                    ">Edit Level <i class="fa fa-edit"></i></b-button>
                  </div>

                  <input slot="selected" slot-scope="props" type="checkbox" class="checkedInvoices rt-checkbox-table"
                    @change="selectProject($event, props.row.wpid, props.row.wtsid)" />

                </v-client-table>
                <h5 class="ml-2">Edit All project Authority :
                  <b-button size="sm" class="ml-2" v-b-modal.editModal variant="primary" @click="
                    sendId2(
                      props.row.id
                    )
                  ">Edit Level <i class="fa fa-edit"></i>
                  </b-button>
                </h5>

                <h5 class="ml-2">Edit All Selected project :
                  <b-button size="sm" class="ml-2" v-b-modal.editModal3 variant="primary" @click="
                    sendId3(
                      props.row.id
                    )
                  ">Edit Level <i class="fa fa-edit"></i>
                  </b-button>
                </h5>
              </div>
            </div>
          </v-client-table>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="editModal" @shown="error = false" ref="editModal" title="Edit Limit" title-tag="h2" centered size="lg"
      hide-footer>
      <form @submit.prevent="validateBeforeSubmit" class="pb-2">
        <label for="">Pass Limit</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-dollar-sign"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" v-validate="'required'" name="passlimit" v-model="passlimit" class="form-control"
            placeholder="$100000" />
        </b-input-group>
        <i v-show="errors.has('passlimit')" class="fa fa-exclamation-triangle"></i><span
          v-show="errors.has('passlimit')" class="help is-danger">The pass limit is required.</span>

        <i v-show="error" class="fa fa-exclamation-triangle mt-3"></i><span v-show="error" class="help is-danger">Error.
          Please contact admin.
        </span>
        <b-button variant="success" class="mt-4" type="submit" block>Save</b-button>
      </form>
    </b-modal>
    <b-modal id="editModal2" @shown="error = false" ref="editModal2" title="Edit Limit" title-tag="h2" centered
      size="lg" hide-footer>
      <form @submit.prevent="validateBeforeSubmit2" class="pb-2">
        <label for="">Pass Limit</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-dollar-sign"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" v-validate="'required'" name="passlimit" v-model="passlimit" class="form-control"
            placeholder="$100000" />
        </b-input-group>
        <i v-show="errors.has('passlimit')" class="fa fa-exclamation-triangle"></i><span
          v-show="errors.has('passlimit')" class="help is-danger">The pass limit is required.</span>

        <i v-show="error" class="fa fa-exclamation-triangle mt-3"></i><span v-show="error" class="help is-danger">Error.
          Please contact admin.
        </span>
        <b-button variant="success" class="mt-4" type="submit" block>Save</b-button>
      </form>
    </b-modal>
    <b-modal id="editModal3" @shown="error = false" ref="editModal2" title="Edit Limit" title-tag="h2" centered
      size="lg" hide-footer>
      <form @submit.prevent="validateBeforeSubmit3" class="pb-2">
        <label for="">Pass Limit</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-dollar-sign"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" v-validate="'required'" name="passlimit" v-model="passlimit" class="form-control"
            placeholder="$100000" />
        </b-input-group>
        <i v-show="errors.has('passlimit')" class="fa fa-exclamation-triangle"></i><span
          v-show="errors.has('passlimit')" class="help is-danger">The pass limit is required.</span>

        <i v-show="error" class="fa fa-exclamation-triangle mt-3"></i><span v-show="error" class="help is-danger">Error.
          Please contact admin.
        </span>
        <b-button variant="success" class="mt-4" type="submit" block>Save</b-button>
      </form>
    </b-modal>
    <b-modal id="editManager" ref="editModal" title="Edit Manager" title-tag="h2" centered size="lg" hide-footer>
      <form @submit.prevent="validateBeforeSubmit4" class="pb-2">
        <label>Dollar Authority</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-dollar-sign"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="number" v-model="authorityManager" v-validate="'required'" name="passlimit"
            class="form-control" placeholder="dollar-sign Limit" />
        </b-input-group>
        <i v-show="errors.has('passlimit')" class="fa fa-exclamation-triangle"></i><span
          v-show="errors.has('passlimit')" class="help is-danger">The dollar-sign limit number is required.</span>
        <b-button variant="success" class="mt-4" type="submit" block>Edit</b-button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { ClientTable, Event } from "vue-tables-2";
import Multiselect from "vue-multiselect";
import VueJsonToCsv from "vue-json-to-csv";
Vue.use(ClientTable);

export default {
  name: "ManagerAssignWts",
  components: {
    ClientTable,
    Event,
    Multiselect,
    VueJsonToCsv,
  },
  data() {
    return {
      columnsProject: [
        "selected",
        "projectname",
        "status",
        "passlimit",
        "actions"
      ],
      optionProject: {
        pagination: {
          show: false
        },
        headings: {
          selected: " ",
          projectname: "Project Name",
          passlimit: "Authority",
          actions: "Action"
        }
      },
      columnsWSSProject: [
        "projectname",
        "status",
      ],
      optionWSSProject: {
        headings: {
          projectname: "Project Name",
        }
      },
      columns: [
        "toggler",
        "name",
        "role",
        "authority",
        "creation_date",
        "status",
        "actions"
      ],
      wtsData: [],
      isToggledRow: [],
      isSelected: [],
      selected: [],
      userStatus: [],
      options: {
        showChildRowToggler: false,
        headings: {
          toggler: " ",
          name: "Name",
          role: "Role",
          authority: "Authority",
          creation_date: "Creation date",
          status: "Status",
        },
        sortable: [
          "name",
          "role",
          "status",
          "authority",
          "creation_date",
        ],
        filterable: [
          "name",
          "role",
          "authority",
          "creation_date",
        ],
        sortIcon: {
          base: "fa",
          up: "fa-sort-up",
          down: "fa-sort-down",
          is: "fa-sort",
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll",
        },
      },
      theme: "bootstrap4",
      template: "default",
      useVuex: false,
      authority: 0,
      wtsid: 0,
      managerid: 0,
      passlimit: 0,
      wpid: 0,
      idManager: 0,
      authorityManager: 0,
    };
  },
  methods: {
    sendInfoManger(row) {
      this.idManager = row.user_id;
      this.authorityManager = row.authority;
      this.$refs.editModal.show();
    },
    activateVendor(id, role) {
      let data = JSON.stringify({
        status: 0,
        id,
        role
      })
      this.$http
        .post("/sys_admin/edit/manager/status", data)
        .then(response => {
          this.$toasted.show('Successfully Manager Status updated.', { type: 'success', duration: '3000' });
          this.loadData();
        })
        .catch(error => {
          this.$toasted.show("Error." + error, { type: "error", duration: "3000" });
        })
    },
    deactivateVendor(id, role) {
      let data = JSON.stringify({
        status: 1,
        id,
        role
      })
      this.$http
        .post("/sys_admin/edit/manager/status", data)
        .then(response => {
          this.$toasted.show('Successfully Manager Status updated.', { type: 'success', duration: '3000' });
          this.loadData();
        })
        .catch(error => {
          this.$toasted.show("Error." + error, { type: "error", duration: "3000" });
        })
    },
    activateProject(id, role) {
      let data = JSON.stringify({
        status: 0,
        id,
        role
      })
      this.$http
        .post("/sys_admin/edit/manager/project/status", data)
        .then(response => {
          this.$toasted.show('Successfully Status updated.', { type: 'success', duration: '3000' });
          this.loadData();
        })
        .catch(error => {
          this.$toasted.show("Error." + error, { type: "error", duration: "3000" });
        })
    },
    deactivateProject(id, role) {
      let data = JSON.stringify({
        status: 1,
        id,
        role
      })
      this.$http
        .post("/sys_admin/edit/manager/project/status", data)
        .then(response => {
          this.$toasted.show('Successfully Manager Status updated.', { type: 'success', duration: '3000' });
          this.loadData();
        })
        .catch(error => {
          this.$toasted.show("Error." + error, { type: "error", duration: "3000" });
        })
    },
    sendId(authority, wtsid, managerid, passlimit, wpid, props) {
      console.log({ props });
      console.log({ authority, wtsid, managerid, passlimit, wpid });
      this.authority = authority;
      this.wtsid = wtsid;
      this.managerid = managerid;
      this.passlimit = passlimit;
      this.wpid = wpid;
    },
    sendId2(id) {
      let data;
      for (const element of this.wtsData) {
        if (element.id == id) {
          console.log(element);
          data = element;
          break;
        }
      }

      console.log({ data });
      this.authority = data.authority;
      this.wtsid = data.projects[0].wtsid;
      this.managerid = managerid;
      this.passlimit = passlimit;
      this.wpid = wpid;
    },
    sendId3(id) {
      console.log("🚀 ~ file: sys_AllUsersList.vue ~ line 254 ~ sendId2 ~ props", id)
      // const data = this.wtsData.some(val => return props.id == this.wtsData.id);
      let data;
      for (const element of this.wtsData) {
        if (element.id == id) {
          console.log(element);
          data = element;
          break;
        }
      }

      console.log({ data });
      this.authority = data.authority;
      this.wtsid = data.projects[0].wtsid;
      this.managerid = managerid;
      this.passlimit = passlimit;
      this.wpid = wpid;
    },
    selectProject(event, wpid, wts) {
      console.log(event.target.checked);
      console.log({ wpid, wts });
      console.log(this.isSelected);
      if (event.target.checked) {
        for (let i = 0; i < this.isSelected.length; i++) {
          if (this.isSelected[i].id == wpid) {
            this.isSelected[i].value = true;
            break;
          }
        }
      } else {
        for (let i = 0; i < this.isSelected.length; i++) {
          if (this.isSelected[i].id == wpid) {
            this.isSelected[i].value = false;
            break;
          }
        }
      }
    },
    toggle(id, event) {
      const upIcon = "fa fa-chevron-circle-up";
      const downIcon = "fa fa-chevron-circle-down";
      if (!this.isToggledRow[id]) {
        event.target.className = upIcon;
        this.isToggledRow[id] = true;
        this.$refs.table.toggleChildRow(id);

      } else {
        event.target.className = downIcon;
        this.isToggledRow[id] = false;
        this.$refs.table.toggleChildRow(id);

      }
    },
    isProject(row) {
      return row.projects && row.projects.length ? true : false
    },
    loadData() {
      this.$http
        .get("/sys_admin/get/all_users")
        .then((response) => {
          this.wtsData = response.data.finalResponse;
          this.isToggledRow = new Array(this.wtsData.length).fill(false);
          this.wtsData.map(data => {
            this.userStatus.push(data.status);
            if (data.role == "Project Manager") {
              data.projects.map(project => {
                this.isSelected.push({ id: project.wpid, value: false })
              })
            }
          })
        })
        .catch((error) => {
          this.$toasted.show("Error.", { type: "error", duration: "3000" });
        });
    },
    validateBeforeSubmit() {
      // this.$validator.validateAll().then((result) => {
      let data = JSON.stringify({
        managerid: this.managerid,
        wtsid: this.wtsid,
        passlimit: this.passlimit,
        // companyid: this.companyid,
        companyid: 5,
      });
      this.$http
        .post("/admin/authority/updateProjectMangerPasslimit", data)
        .then((response) => {
          this.$refs.editModal.hide();
          this.loadData();
        })
        .catch((error) => {
          this.error = true;
        });
    },
    validateBeforeSubmit2() {
      let data = JSON.stringify({
        wpid: this.wpid,
        passlimit: this.passlimit,
      });
      this.$http
        .post("/admin/authority/updateOneProjectMangerPasslimit", data)
        .then((response) => {
          this.$refs.editModal2.hide();
          this.loadData();
        })
        .catch((error) => {
          this.error = true;
        });
    },
    validateBeforeSubmit3() {
      let isError = false;
      for (let i = 0; i < this.isSelected.length; i++) {
        if (this.isSelected[i].value) {
          let data = JSON.stringify({
            wpid: this.isSelected[i].id,
            passlimit: this.passlimit,
          });
          this.$http
            .post("/admin/authority/updateOneProjectMangerPasslimit", data)
            .then((response) => {
              console.log("Updated..");
            })
            .catch((error) => {
              isError = true;
            });
        }
      }
      if (!isError) {
        this.$refs.editModal2.hide();
        location.reload();
        this.loadData();
      } else {
        this.error = true;
      }
    },
    validateBeforeSubmit4() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          console.log(result);
          let data = JSON.stringify({
            id: this.idManager,
            // companyid: this.$route.params.id,
            companyid: 5,
            passlimit: this.authorityManager,
          });
          this.$http
            .post("/sys_admin/edit/manager/passlimit", data)
            .then((response) => {
              if (response.status === 200) {
                this.$toasted.show("Update Successful.", {
                  type: "success",
                  duration: "3000",
                });
                this.$refs.editModal.hide();
                this.loadData();
              }
            })
            .catch((error) => {
              this.$toasted.show("Error.", { type: "error", duration: "3000" });
              this.error = true;
            });
          return;
        }
      });
    },
  },
  mounted: function () {
    this.loadData();
  },
};
</script>

<style lang="scss">
.fa-chevron-circle-up {
  font-size: 20px;
  color: red;
}

.fa-chevron-circle-down {
  font-size: 20px;
  color: green;
}

#VueTables_th--toggler {
  width: 3%;
}

#VueTables_th--selected {
  width: 3%;
}

#VueTables_th--actions {
  width: 8.5%;
}
label {
  display: block !important;
}
</style>
